<template>
  <div style="width:200px ;height: 100vh; background: #000; max-height: 1000px;">
    <el-aside width="200px" style="background: #000; height: 100%; ">
      <div
        style="height: 60px; background: #000; line-height: 60px; font-size: 20px; display: flex; align-items: center; justify-content: center">
        <img :src="require('@/assets/logo.png')" style="width: 60px;" alt="">
        <span class="logo-title" style="color: #fff;">福绵体育</span>
      </div>
      <el-menu style="border: none;" default-active="2" class="el-menu-vertical-demo" @open="handleOpen"
        @close="handleClose" background-color="#000" text-color="#bfcbd9" active-text-color="#f4f4f5" router>
        <el-menu-item index="/Home">
          <el-icon>
            <House />
          </el-icon>
          <span>首页</span>
        </el-menu-item>
        <el-sub-menu index="2">
          <template #title>
            <el-icon>
              <Basketball />
            </el-icon>
            <span>俱乐部</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="showEventInfo1" index="/RegionalClub">地方俱乐部管理</el-menu-item>
            <el-menu-item v-if="showEventInfo2" index="/YouthIevel">青训球员人才库</el-menu-item>
          </el-menu-item-group>

        </el-sub-menu>
        <el-sub-menu index="3">
          <template #title>
            <el-icon>
              <List />
            </el-icon>
            <span>科学训练</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="showEventInfo3" index="/trainingData">训练数据</el-menu-item>
            <el-menu-item v-if="showEventInfo6" index="/analyse">训练数据分析</el-menu-item>
            <el-menu-item v-if="showEventInfo7" index="/facility">器材申请</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>

        <el-sub-menu index="4" v-if="showEventInfo12">
          <template #title>
            <el-icon>
              <Avatar />
            </el-icon>
            <span>人员管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="showEventInfo4" index="/coach">教练管理</el-menu-item>
            <el-menu-item v-if="showEventInfo5" index="/user">用户管理</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="5" v-if="showEventInfo12">
          <template #title>
            <el-icon>
              <InfoFilled />
            </el-icon>
            <span>赛事管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-if="showEventInfo8" index="/EventManagement">赛事信息</el-menu-item>
            <el-menu-item v-if="showEventInfo9" index="/field">报名信息</el-menu-item>
            <el-menu-item v-if="showEventInfo10" index="/inform">通知公告</el-menu-item>
            <el-menu-item v-if="showEventInfo11" index="/logs">操作日志</el-menu-item>
          </el-menu-item-group>


        </el-sub-menu>
      </el-menu>
    </el-aside>


  </div>

</template>

<script setup>
import {
  House,
  Basketball,
  List,
  Avatar,
  InfoFilled,
} from '@element-plus/icons-vue'
</script>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      userpost: "",
      userid: "",
      showEventInfo1: true,
      showEventInfo2: true,
      showEventInfo3: true,
      showEventInfo4: true,
      showEventInfo5: true,
      showEventInfo6: true,
      showEventInfo7: true,
      showEventInfo8: true,
      showEventInfo9: true,
      showEventInfo10: true,
      showEventInfo11: true,
      showEventInfo12: true,
    };
  },
  methods: {
    userinfow() {
      axios.get('/api/userinfotow').then((rel) => {
        this.userpost = rel.data.data['post']
        this.userid = rel.data.data['userid']
        this.Role()
      })
    },

    Role() {
      const prefix = this.userid.toString().slice(0, 2); //截取用户id的前两位

      console.log("前两位", prefix)
      if (prefix == '10') {

        this.showEventInfo1 = true
        this.showEventInfo2 = true
        this.showEventInfo3 = true
        this.showEventInfo4 = true
        this.showEventInfo5 = true
        this.showEventInfo6 = true
        this.showEventInfo7 = true
        this.showEventInfo8 = true
        this.showEventInfo9 = true
        this.showEventInfo10 = true
        this.showEventInfo11 = true

      } else if (prefix == '20') {
        //教练
        this.showEventInfo1 = true
        this.showEventInfo2 = true
        this.showEventInfo3 = true
        this.showEventInfo4 = false
        this.showEventInfo5 = false
        this.showEventInfo6 = true
        this.showEventInfo7 = true
        this.showEventInfo8 = false
        this.showEventInfo9 = false
        this.showEventInfo10 = false
        this.showEventInfo11 = false
        this.showEventInfo12 = false
        console.log("别的权限")
      } else if (prefix == '70') {
        //工作人员
        this.showEventInfo1 = true
        this.showEventInfo2 = false
        this.showEventInfo3 = false
        this.showEventInfo4 = true
        this.showEventInfo5 = true
        this.showEventInfo6 = false
        this.showEventInfo7 = false
        this.showEventInfo8 = true
        this.showEventInfo9 = true
        this.showEventInfo10 = true
        this.showEventInfo11 = true
        this.showEventInfo12 = true

        console.log("别的权限")
      }
    }

  },
  mounted() {
    this.userinfow()

  },
  // created() {
  //   this.userinfow()
  // },
}
</script>

<style scoped></style>