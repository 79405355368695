<!-- 青训球员 -->
<template>
  <div class="TableA">
    <el-scrollbar height="700px">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="工号" prop="id" />
        <el-table-column label="图片" prop="picture" width="150px">
          <template #default="scope">
            <el-image :src="getImage(scope.row.picture)" style="width:100px ;"
              preview-src-list="=[ `/aip/common/download?name=${row.image}` ]"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="姓名" prop="name" />
        <el-table-column label="职位" prop="position" />
        <el-table-column label="年龄" prop="age" />
        <el-table-column label="年薪" prop="pay" />
        <el-table-column label="合同年限" prop="contract_term" />
        <el-table-column align="right">
          <template #header>
            <div>
              <el-icon @click="SearchID()">
                <Search />
              </el-icon>
              <input type="text" style="width: 100px; height: 30px;  border-radius: 15px; margin: 5px; "
                placeholder="用户名查找" v-model="id">

            </div>
          </template>
          <template #default="scope">
            <el-button plain size="small" @click="handleEdit(scope.$index, scope.row)"
              v-on:click="dialogFormVisible = true">
              编辑
            </el-button>
            <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)" plain
              v-on:click="centerDialogVisible = true">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>
    <div class="tanchuang">
      <el-dialog v-model="dialogFormVisible" title="编辑" width="500">
        <el-form :model="form">
          <el-form-item label="姓名" :label-width="formLabelWidth">
            <el-input v-model="name" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="职位" :label-width="formLabelWidth">
            <!-- <el-select v-model="form.region" placeholder="填写职位">
              <el-option label="Zone No.1" value="shanghai" />
              <el-option label="Zone No.2" value="beijing" />
            </el-select> -->
            <el-input v-model="position" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="年龄" :label-width="formLabelWidth">
            <el-input v-model="age" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="年薪" :label-width="formLabelWidth">
            <el-input v-model="pay" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="合同年限" :label-width="formLabelWidth">
            <el-input v-model="contract_term" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <!-- <el-form-item label="上传照片" :label-width="formLabelWidth">
            <el-input v-model="form.name" autocomplete="off"  style="width: 200px; height: 40px;"/>
          </el-form-item> -->
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogFormVisible = false"> 取消</el-button>
            <el-button type="primary" @click="dialogFormVisible = false" v-on:click="editplay()">
              保存
            </el-button>
          </div>
        </template>
      </el-dialog>

    </div>
    <div class="deleteDialog">
      <el-dialog v-model="centerDialogVisible" title="消息" width="500" center>
        <span>
          是否确定删除此条数据
        </span>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="centerDialogVisible = false">否</el-button>
            <el-button type="primary" @click="centerDialogVisible = false" v-on:click="deletepy()">
              是
            </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>

  <div class="pagination">
    <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page="tablePage.pageNum"
      :page-size="tablePage.pageSize" :page-sizes="pageSizes" :total="tablePage.total" @size-change="handleSizeChange"
      @current-change="handlePageChange" />
  </div>

  <!-- <button v-on:click="playerApl()" style="border-radius: 10px; background: #31dd0e;"><el-icon>
      <Refresh />
    </el-icon>刷新数据</button>
  <router-link to='/Home/team/add'>
    <button style="border-radius: 10px; background: #fff;"><el-icon>
        <Plus />
      </el-icon>添加成员</button>
  </router-link> -->

</template>
<style>
.custom-btn {
  background-color: #545c64;
  border-color: #545c64;

  margin-left: 650px;
}
.head {
 border: 1px solid #000;
 width: 100%;
 height: 50px;
}

.avatar-uploader .el-upload {
 border: 1px dashed #d9d9d9;
 border-radius: 6px;
 cursor: pointer;
 position: relative;
 overflow: hidden;
}

.avatar-uploader .el-upload:hover {
 border-color: #409EFF;
}

.avatar-uploader-icon {
 font-size: 28px;
 color: #8c939d;
 width: 178px;
 height: 178px;
 line-height: 178px;
 text-align: center;
}

.avatar {
 width: 178px;
 height: 178px;
 display: block;
}

.for {
 display: flex;
 flex-direction: column;
 align-items: center;
 /* 垂直居中 */
 /* 注意：这种方式会影响所有表单项，包括它们的标签 */
}
</style>

<script setup>
import { ElMessageBox } from 'element-plus'
import {
  Search, Plus, Refresh
} from '@element-plus/icons-vue'

import { reactive, ref } from 'vue'
const dialogFormVisible = ref(false)
const centerDialogVisible = ref(false)
const formLabelWidth = '140px'

// const form = reactive({

//   delivery: false,
//   type: [],
//   resource: '',
//   desc: '',
// })
</script>




<script>
import axios from 'axios'
// import { id } from 'element-plus/es/locale';


export default {
  data() {
    return {
      PlayId: '',
      name: '',
      position: '',
      age: '',
      pay: '',
      contract_term: '',
      deleteId: '',

      tableData: [],
      tablePage: {
        pageNum: 1, // 第几页
        pageSize: 10, // 每页多少条
        total: 0 // 总记录数
      },
      pageSizes: [10, 20, 30],
      id: '2',
    }
  },
  methods: {

    handlePageChange(currentPage) {
      this.tablePage.pageNum = currentPage
      // 在此刷新数据
    },
    handleSizeChange(pageSize) {
      this.tablePage.pageSize = pageSize
      // 在此刷新数据
    },

    // playerApl() {
    //   const token = localStorage.getItem('user-token');
    //   if (token) {
    //     axios.defaults.headers.common['Authorization'] = `${token}`;//获秘钥token
    //     axios.get('/api/Home/Playerlist?pageNum=1&pageSize=10',).then((rel) => {

    //       console.log(rel);
    //       this.tableData = rel.data.data.records
    //       // this.list = res.data.list
    //       // this.tablePage.total = res.data.total
    //       // this.tableData=rel.data
    //     })
    //   }
    // },
    // SearchID() {
    //   const id = this.id;
    //   const token = localStorage.getItem('user-token');
    //   if (token) {
    //     axios.defaults.headers.common['Authorization'] = `${token}`;//将token赋值到请求头
    //     if (id != null) {
    //       axios.get(`/api/Home/Playerlist/${id}`).then((rel) => {
    //         console.log(rel.data)
    //         this.tableData = rel.data.data
    //       })
    //     }


    //   }
    // },
    handleEdit(number, User) {
      console.log(number, User)
      this.PlayId = User.id
      this.name = User.name
      this.position = User.position
      this.age = User.age
      this.pay = User.pay
      this.contract_term = User.contract_term
      console.log(this.name)
    },

    // editplay() {
    //   const token = localStorage.getItem('user-token');
    //   if (token) {
    //     axios.defaults.headers.common['Authorization'] = `${token}`;
    //     axios.put('/api/Home/UpPlay', {
    //       id: this.PlayId,
    //       name: this.name,
    //       position: this.position,
    //       age: this.age,
    //       pay: this.pay,
    //       contract_term: this.contract_term
    //     }).then((rel) => {
    //       console.log(rel)
    //       this.tableData = rel.data.data
    //     })
    //   }
    // },


    handleDelete(index, User) {
      console.log(index, User)
      this.deleteId = User.id

    },

    // deletepy() {
    //   const id=this.deleteId
    //   const token = localStorage.getItem('user-token');
    //   if (token) {
    //     axios.defaults.headers.common['Authorization'] = `${token}`;
    //     axios.delete(`/api/Home/Delete?id=${id}`).then((rel) => {
    //       console.log(rel)
    //       this.tableData = rel.data.data

    //     })
    //   }
    // },

    getImage(image) {
      return `/api/common/download?name=${image}`
    },
  },
  // created() {
  //   this.playerApl()
  // },

  
}
</script>

