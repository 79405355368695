<template>
  <!-- <h1>训练数据</h1> -->
  <div style="width: 100%; height: 50px;  display: flex; ">
    <p style="text-align:left"> 编号：<el-select filterable allow-create default-first-option v-model="valueid"
        placeholder="Select" size="large" style="width: 100px">
        <el-option v-for="item in options" :key="item.userid" :label="item.userid" :value="item.userid" />
      </el-select>
    </p>
    <p style="text-align:left;margin-left: 30px; margin-right: 10px;"> 时间：<el-select filterable allow-create
        default-first-option v-model="valuetime" placeholder="Select" size="large" style="width: 250px">
        <el-option v-for="item in optime" :key="item.lotimes" :label="item.lotimes" :value="item.lotimes" />

      </el-select>
    </p>
    <el-button type="primary" @click="condition(1)">精确查询</el-button>
    <el-button type="primary" v-on:click="addDialog = true">添加数据</el-button>
  </div>

  <div class="main">
    <el-scrollbar height="700px">
      <el-table :data="tableData" style="width: 100%" height="700">
        <el-table-column prop="athletesid" label="ID" width="50" header-align="center" align="center" />
        <el-table-column prop="trainingid" label="编号" width="40" header-align="center" align="center" />
        <el-table-column prop="name" label="球员姓名" width="70" header-align="center" align="center" />
        <el-table-column prop="shots" label="投篮总数" width="80" header-align="center" align="center" />
        <el-table-column prop="towshots" label="两分投篮总数" width="80" header-align="center" align="center" />
        <el-table-column prop="towhit" label="两分投中数" width="80" />
        <el-table-column prop="threeshots" label="三分投篮总数" width="80" header-align="center" align="center" />
        <el-table-column prop="threehit" label="三分投中数" width="80" header-align="center" align="center" />
        <el-table-column prop="layup" label="上篮总数" width="80" header-align="center" align="center" />
        <el-table-column prop="layuphit" label="上篮中球数" width="80" header-align="center" align="center" />
        <el-table-column prop="freethrow" label="罚篮总次数" width="80" header-align="center" align="center" />
        <el-table-column prop="freethrowhit" label="罚篮中次数" width="80" header-align="center" align="center" />
        <el-table-column prop="touchheight" label="原地摸高" width="80" header-align="center" align="center" />
        <el-table-column prop="vitalcapacity" label="肺活量" width="80" header-align="center" align="center" />
        <el-table-column prop="shuttlerun" label="折返跑" width="80" header-align="center" align="center" />
        <el-table-column prop="weightsquat" label="深蹲负重" width="80" header-align="center" align="center" />
        <el-table-column prop="slidestep" label="滑步训练" width="80" header-align="center" align="center" />
        <el-table-column prop="bench" label="负重卧推" width="80" header-align="center" align="center" />
        <el-table-column prop="waistline" label="腰围" width="60" header-align="center" align="center" />
        <el-table-column prop="lotimes" label="时间" width="80" header-align="center" align="center" />
        <el-table-column>
          <template #header>
            <div style="display: flex;">
              <el-icon @click="Searchqinxun()" style="width: 20px; height: 30px;">
                <Search />
              </el-icon>
              <input type="text"
                style=" color: #000; width:100px ; height: 20px; border-radius: 15px; margin: 5px;  border: 1px solid #000;"
                v-model="athletesid">
            </div>
          </template>
          <template #default="scope">
            <el-button v-if="showEventInfo1" plain size="small" @click="handleEdit(scope.$index, scope.row)"
              v-on:click="dialogFormVisible = true">
              编辑
            </el-button>
            <el-button v-if="showEventInfo1" size="small" type="danger" @click="handleDelete(scope.$index, scope.row)"
              plain v-on:click="centerDialogVisible = true">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>
    <div class="tanchuang">
      <el-dialog v-model="dialogFormVisible" title="编辑" width="500">
        <el-form :model="form" class="for">
          <!-- <el-form-item>
           <div>
             <el-upload ref="uploadRef" class="avatar-uploader" action="/api/common/upload" :show-file-list="false"
               :file-list="fileList" @success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
               :on-change="handleFileChange" :auto-upload="false">
               <img v-if="imageUrl" :src="imageUrl" class="avatar">
               <i v-else class="el-icon-plus avatar-uploader-icon"></i>
             </el-upload>
             <button @click="submitUpload()" type="button">上传图片</button>
           </div>
         </el-form-item> -->
          <el-form-item label="姓名">
            <el-input v-model="name" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="两分投中数">
            <el-input v-model="liangfen" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="3分投中次数">
            <!-- <el-select v-model="form.region" placeholder="填写职位">
             <el-option label="Zone No.1" value="shanghai" />
             <el-option label="Zone No.2" value="beijing" />
           </el-select> -->
            <el-input v-model="sanfen" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="上篮中次数">
            <el-input v-model="shanglan" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="罚篮中次数">
            <el-input v-model="falan" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="原地起跳摸高">
            <el-input v-model="yuandi" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="肺活量">
            <el-input v-model="feihuliang" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="折返跑">
            <el-input v-model="zhefanpao" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="负重深蹲">
            <el-input v-model="fuzhongshendui" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="滑步训练">
            <el-input v-model="huabu" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="负重卧推">
            <el-input v-model="wotui" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="腰围">
            <el-input v-model="yaowei" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <!-- <el-form-item label="上传照片" :label-width="formLabelWidth">
           <el-input v-model="form.name" autocomplete="off"  style="width: 200px; height: 40px;"/>
         </el-form-item> -->
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogFormVisible = false"> 取消</el-button>
            <el-button type="primary" @click="dialogFormVisible = false" v-on:click="editplay()">
              保存
            </el-button>
          </div>
        </template>
      </el-dialog>

      <div class="deleteDialog">
        <el-dialog v-model="centerDialogVisible" title="消息" width="500" center>
          <span>
            是否确定删除此条数据
          </span>
          <template #footer>
            <div class="dialog-footer">
              <el-button @click="centerDialogVisible = false">否</el-button>
              <el-button type="primary" @click="centerDialogVisible = false" v-on:click="deleteat()">
                是
              </el-button>
            </div>
          </template>
        </el-dialog>
      </div>
    </div>
    <div>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>


    <div class="addDialog">
      <el-dialog v-model="addDialog" title="添加训练数据" width="500" :before-close="handleClose">
        <div class="input-container">
          <div class="input-wrapper" style="display: flex;">
            <div style="margin-left: 100px;">球员id：</div><el-input v-model="trainingidAdd" placeholder="球员id"
              style="width: 200px;"></el-input>
          </div>
          <div class="input-wrapper" style="display: flex;">
            <div style="margin-left: 113px;">2分投中数：</div><el-input v-model="towhitAdd" placeholder="2分投中数"
              style="width: 200px;"></el-input>
          </div>
          <div class="input-wrapper" style="display: flex;">
            <div style="margin-left: 113px;">3分投中次数：</div><el-input v-model="threehitAdd" placeholder="3分投中次数："
              style="width: 200px;"></el-input>
          </div>
          <div class="input-wrapper" style="display: flex;">
            <div style="margin-left: 100px;">上篮中次数：</div><el-input v-model="layuphitAdd" placeholder="上篮中次数："
              style="width: 200px;"></el-input>
          </div>
          <div class="input-wrapper" style="display: flex;">
            <div style="margin-left: 100px;">罚篮中次数：</div><el-input v-model="freethrowhitAdd" placeholder="罚篮中次数："
              style="width: 200px;"></el-input>
          </div>
          <div class="input-wrapper" style="display: flex;">
            <div style="margin-left: 100px;">原地起跳摸高：</div><el-input v-model="touchheightAdd" placeholder="原地起跳摸高："
              style="width: 200px;"></el-input>
          </div>
          <div class="input-wrapper" style="display: flex;">
            <div style="margin-left: 100px;">肺活量：</div><el-input v-model="vitalcapacityAdd" placeholder="肺活量："
              style="width: 200px;"></el-input>
          </div>
          <div class="input-wrapper" style="display: flex;">
            <div style="margin-left: 100px;">折返跑：</div><el-input v-model="shuttlerunAdd" placeholder="折返跑："
              style="width: 200px;"></el-input>
          </div>
          <div class="input-wrapper" style="display: flex;">
            <div style="margin-left: 100px;">负重深蹲：</div><el-input v-model="weightsquatAdd" placeholder="负重深蹲："
              style="width: 200px;"></el-input>
          </div>
          <div class="input-wrapper" style="display: flex;">
            <div style="margin-left: 100px;">滑步训练：</div><el-input v-model="slidestepAdd" placeholder="滑步训练："
              style="width: 200px;"></el-input>
          </div>
          <div class="input-wrapper" style="display: flex;">
            <div style="margin-left: 100px;">负重卧推：</div><el-input v-model="benchAdd" placeholder="负重卧推："
              style="width: 200px;"></el-input>
          </div>
          <div class="input-wrapper" style="display: flex;">
            <div style="margin-left: 100px;">腰围：</div><el-input v-model="waistlineAdd" placeholder="腰围："
              style="width: 200px;"></el-input>
          </div>
          <div class="input-wrapper" style="display: flex;">
            <div style="margin-left: 100px;">完成时间：</div>
            <el-col :span="11">
              <el-date-picker v-model="date1" type="date" placeholder="选择时间" style="width: 100%" format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"  />
            </el-col>
          </div>
        </div>

        <template #footer>
          <div class="dialog-footer">
            <el-button @click="addDialog = false">取消</el-button>
            <el-button type="primary" @click="addDrill()">
              确定
            </el-button>
          </div>
        </template>
      </el-dialog>


    </div>
  </div>
</template>
<script setup>
import {
  Search, Plus, Refresh
} from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
const dialogFormVisible = ref(false)
const centerDialogVisible = ref(false)
</script>

<script>
import { reactive, ref } from 'vue'
import axios from 'axios'
export default {

  data() {
    return {
      tableData: [],
      currentPage: 1,  //当前页
      pageSize: 10, //页面数据条数
      total: '100',
      name: '',
      userid: '',
      liangfen: '',
      sanfen: '',
      shanglan: '',
      //  imageUrl: '',//编辑照片显示路径
      falan: '',
      yuandi: '',
      feihuliang: '',
      zhefanpao: '',
      fuzhongshendui: '',
      //  sqlimg:'',
      ipshijian: '',
      ipid: '',
      showEventInfo1: false,
      huabu: '',
      wotui: '',
      yaowei: '',
      trainid: '',
      athid: '',
      valueid: '',
      valuetime: null,
      options: [],
      optime: [],
      addDialog: false,

      trainingidAdd: '',
      towhitAdd: '',
      threehitAdd: '',
      layuphitAdd: '',
      freethrowhitAdd: '',
      touchheightAdd: '',
      vitalcapacityAdd: '',
      shuttlerunAdd: '',
      weightsquatAdd: '',
      slidestepAdd: '',
      benchAdd: '',
      waistlineAdd: '',
      date1: '',



    }
  },

  methods: {

    //换条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.info()
    },
    //换页数
    handleCurrentChange(val) {
      this.currentPage = val;
      this.info()
    },
    //获取球员信息
    info() {
      axios.get(`/api/drill/drilllist?pageSize=${this.pageSize}&pageNum=${this.currentPage}`).then((rel) => {
        console.log(rel)
        this.tableData = rel.data.data.records
        this.currentPage = rel.data.data.current
        this.pageSize = rel.data.data.size
        this.total = rel.data.data.total
      })
    },

    infotiom() {
      axios.get(`/api/drill/datatiom`).then((rel) => {
        console.log(rel)
        this.optime = rel.data.data
        console.log(this.optime)
      })
    },

    infoid() {//球员编号
      axios.get(`/api/athletes/Training`).then((rel) => {
        // console.log(rel)
        this.options = rel.data.data
        // console.log(this.options)
      })
    },
    //上传图片完成回调
    //  handleAvatarSuccess(res, file) {
    //    this.sqlimg = res.data;
    //    console.log(this.sqlimg)
    //    this.imageUrl = URL.createObjectURL(file.raw);
    //    console.log(this.imageUrl)
    //  },
    //上传图片过滤
    //  beforeAvatarUpload(file) {
    //    const isJPG = file.type === 'image/jpeg';
    //    const isLt2M = file.size / 1024 / 1024 < 2;

    //    if (!isJPG) {
    //      this.$message.error('上传头像图片只能是 JPG 格式!');
    //    }
    //    if (!isLt2M) {
    //      this.$message.error('上传头像图片大小不能超过 2MB!');
    //    }
    //    return isJPG && isLt2M;
    //  },
    //信息回显
    handleEdit(number, User) {
      console.log(number, User)
      this.athid = User.athletesid
      this.name = User.name
      this.liangfen = User.towhit
      this.sanfen = User.threehit
      this.shanglan = User.layuphit
      this.falan = User.freethrowhit
      this.yuandi = User.touchheight
      this.feihuliang = User.vitalcapacity
      this.huabu = User.slidestep
      this.wotui = User.bench
      this.yaowei = User.waistline
      this.zhefanpao = User.shuttlerun
      this.fuzhongshendui = User.weightsquat
      this.trainid = User.trainingid
    },
    //编辑
    editplay() {
      axios.put('/api/drill/updrill', {
        athletesid: this.athid,
        trainingid: this.trainid,
        towhit: this.liangfen,
        threehit: this.sanfen,
        layuphit: this.shanglan,
        freethrowhit: this.falan,
        touchheight: this.yuandi,
        vitalcapacity: this.feihuliang,
        slidestep: this.huabu,
        bench: this.wotui,
        waistline: this.yaowei,
        shuttlerun: this.zhefanpao,
        weightsquat: this.fuzhongshendui
      }).then((rel) => {
        if (rel.data.code == 200) {
          ElMessage({
            message: '修改成功',
            type: 'error',
          })
          this.info()
        } else {
          ElMessage({
            message: '修改失败',
            type: 'error',
          })
        }

      })
    },
    //查询方法
    Searchqinxun() {
      const id = this.athletesid
      axios.get(`/api/drill/seledrillid?id=${id}`).then((rel) => {
        console.log(rel);
        if (rel.data.code == 200) {
          this.tableData = rel.data.data
        } else {
          ElMessage({
            message: '查询失败',
            type: 'error',
          })
        }

      })
    },
    // 删除方法
    deleteat() {
      const id = this.trainid
      axios.delete(`/api/drill/dedrill?id=${id}`).then((rel) => {
        console.log(rel);
        if (rel.data.code == 200) {
          ElMessage({
            message: '删除成功',
            type: 'succeed',
          })
          this.info()
        } else {
          ElMessage({
            message: '删除失败',
            type: 'error',
          })
        }

      })
    },
    //上传文件提交选定表
    //  submitUpload() {
    //    this.$refs.uploadRef.submit();
    //  },
    //删除按钮行数选定
    handleDelete(index, User) {
      console.log(index, User)
      this.trainid = User.trainingid
    },

    // upimg(){
    //   axios.post('/api/common/upload',{

    //   }).then((rel)=>{
    //     console.log(rel)
    //   })
    // },
    //编辑上传图片变换
    handleFileChange(file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]
      }

      // 当文件状态发生变化时触发，此处用于更新预览图片  
    },
    //身份认证
    userpost() {
      const posts = localStorage.getItem("posts")
      console.log("身份验证", posts)
      if (posts == "后端管理员" || posts == "教练负责人") {
        console.log("身份验证成功")
        this.showEventInfo1 = true;
      } else {
        console.log("身份验证结束")
        this.showEventInfo1 = false;
      }
    },
    //筛选
    condition(s) {
      axios.post('/api/drill/condition', {
        amin: this.valueid,//id
        lotimedata: this.valuetime,//时间
        ctype: s,
      }).then((rel) => {
        console.log(rel)
        this.tableData = rel.data.data
      })
    },
    addDrill() {
      axios.post('/api/drill/drilladd', {
        athletesid: this.trainingidAdd,//赛事名称
        towhit: this.towhitAdd,//赛事负责人
        threehit: this.threehitAdd,//比赛时间
        layuphit: this.layuphitAdd,//赞助商
        freethrowhit: this.freethrowhitAdd,//报名时间
        touchheight: this.touchheightAdd,//报名状态
        vitalcapacity: this.vitalcapacityAdd,
        shuttlerun: this.shuttlerunAdd,
        weightsquat: this.weightsquatAdd,
        slidestep: this.slidestepAdd,
        bench: this.benchAdd,
        waistline:this.waistlineAdd,
        lotimes:this.date1,
        shots:100,
        towshots:30,
        threeshots:70,
        layup:40,
        freethrow:50,
      }).then((rel) => {
        if (rel.data.code == 200) {
          ElMessage({
            message: '成功',
            type: 'success',
          })
          this.addDialog = false
          this.info()
        } else {
          ElMessage({
            message: '失败',
            type: 'error',
            plain: true,
          })
          this.addDialog = false
        }

      })
    },



    //  getImage(image) {//图片遍历
    //    return `/api/common/download?name=${image}`
    //  },
  },

  
  mounted() {
    this.info();
    this.userpost()
    this.infotiom()
    this.infoid()
  },
  // created() {
   
  // },



}
</script>

<style scoped>
.head {
  border: 1px solid #000;
  width: 100%;
  height: 50px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.for {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 垂直居中 */
  /* 注意：这种方式会影响所有表单项，包括它们的标签 */
}
</style>