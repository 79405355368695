<template>
  <!-- 设备功能 -->
  <div class="main">
    <el-scrollbar height="700px">
      <el-table :data="tableData" style="width: 100%" height="700">
        <el-table-column prop="facilityid" label="ID" width="180" header-align="center" align="center" />
        <el-table-column prop="facilityname" label="设备名称" width="100" header-align="center" align="center" />
        <el-table-column prop="facilitystart" label="设备状态" width="100" header-align="center" align="center">
          <template v-slot="scope">
            <div>
              {{ scope.row.facilitystart === 0 ? '空闲' : '使用' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="facilitynumber" label="设备数量" width="100" header-align="center" align="center" />
        <el-table-column prop="facilityaudit" label="审核人" width="100" header-align="center" align="center" />
        <el-table-column prop="coachname" label="申请人" width="100" header-align="center" align="center" />
        <el-table-column prop="auditstart" label="审核状态" width="100" header-align="center" align="center">
          <template v-slot="scope">
            {{ scope.row.auditstart === 0 ? '通过' : (scope.row.auditstart === 1 ? '未通过' : (scope.row.auditstart ===
              2 ? '提交审核' : '正在审核')) }}
          </template>
        </el-table-column>
        <el-table-column prop="facilitytime" label="通过时间" width="180" header-align="center" align="center" />
        <el-table-column>
          <template #header >
            <el-button v-if="showEventInfo2" size="small" type="primary" v-on:click="addDialogVisible = true">
              器材需求
            </el-button>
          </template>
          <template #default="scope">
            <el-button v-if="showEventInfo2" size="small" type="danger" @click="handleDelete(scope.$index, scope.row)"
              plain v-on:click="centerDialogVisible = true">
              删除
            </el-button>
            <el-button v-if="showEventInfo1" size="small" type="success" @click="handleEdit(scope.$index, scope.row, 0)"
              plain v-on:click="editplay">
              通过审核
            </el-button>
            <el-button v-if="showEventInfo1" size="small" type="danger" @click="handleEdit(scope.$index, scope.row, 1)"
              plain v-on:click="liyouDialogVisible = true">
              不通过审核
            </el-button>
            <el-button v-if="scope.row.auditstart === 0 ? false : true" size="small" type="primary"
              @click="handleEdit(scope.$index, scope.row)" plain v-on:click="xiangqingDialogVisible = true">
              审核详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>

    <div class="deleteDialog">
      <el-dialog v-model="centerDialogVisible" title="消息" width="500" center>
        <span>
          是否确定删除此条数据
        </span>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="centerDialogVisible = false">否</el-button>
            <el-button type="primary" @click="centerDialogVisible = false" v-on:click="deleteat()">
              是
            </el-button>
          </div>
        </template>
      </el-dialog>
    </div>

    <el-dialog v-model="xiangqingDialogVisible" title="消息" width="500" center>
      <span>
        {{ this.shenheneirong }}
      </span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="xiangqingDialogVisible = false">否</el-button>
          <el-button type="primary" @click="xiangqingDialogVisible = false">
            是
          </el-button>
        </div>
      </template>
    </el-dialog>


    <el-dialog v-model="liyouDialogVisible" title="消息" width="500" center>
      <span>
        <el-input v-model="inputneirong" placeholder="请输入内容"></el-input>
      </span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="liyouDialogVisible = false">否</el-button>
          <el-button type="primary" @click="editplay()" v-on:click="liyouDialogVisible = false">
            是
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="addDialogVisible" title="消息" width="500" center>
      <el-form :model="form" label-width="auto" style="max-width: 600px">
       
        <el-form-item label="器材选择">
          <el-select v-model="form.facilityname" placeholder="please select your zone">
            <el-option v-for="items in all" :key="items.value" :label="items.name" :value="items.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="数量">
          <el-input v-model="form.facilitynumber" />
        </el-form-item>
        
        <el-form-item label="申请人">
          <el-input v-model="form.coachname" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="addDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="add()" v-on:click="addDialogVisible = false">
            保存
          </el-button>
        </div>
      </template>
    </el-dialog>

  </div>
  <div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>


</template>
<script setup>
import {
  Search, Plus, Refresh
} from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
const centerDialogVisible = ref(false)
const xiangqingDialogVisible = ref(false)
const liyouDialogVisible = ref(false)
const addDialogVisible = ref(false)
</script>

<script>
import { reactive, ref } from 'vue'
import axios from 'axios'
export default {

  data() {

    const form = ref({
      facilityname: '',
      facilitynumber:'',
      coachname:'',
    })
    return {
      tableData: [],
      currentPage: 1,  //当前页
      pageSize: 10, //页面数据条数
      total: '100',
      showEventInfo1: '',
      showEventInfo2: '',
      seaid: '',
      deleteId: '',
      shenheneirong: '',
      nub: '',
      inputneirong: '',
      form,
      all:[
        { name:"坐姿下拉器械", value:"坐姿下拉器械"},
      {name:"坐姿划船机", value:"坐姿划船机"},
      {name:"坐姿划船机", value:"坐姿划船机"},
      {name:"史密斯", value:"史密斯"},
      {name:"龙门架", value:"龙门架"},
      {name:"杠铃", value:"杠铃"},
      {name:"哑铃", value:"哑铃"}]
    }

  },

  methods: {

    //换条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.info()
    },
    //换页数
    handleCurrentChange(val) {
      this.currentPage = val;
      this.info()
    },
    //获取用户信息
    info() {
      axios.get(`/api/facility/facilityinfo?pageSize=${this.pageSize}&pageNum=${this.currentPage}`).then((rel) => {
        console.log(rel)
        this.tableData = rel.data.data.records
        this.currentPage = rel.data.data.current
        this.pageSize = rel.data.data.size
        this.total = rel.data.data.total
      })
    },

    editplay() {
      axios.put('/api/facility/Upfacility', {
        facilityid: this.seaid,
        auditstart: this.nub,
        shenheliyou: this.inputneirong,
        facilitynumber:this.fnb
      }).then((rel) => {
        ElMessage({
          message: '成功',
          type: 'success',
        })
        this.info()
      })
    },
    //用户信息回显
    handleEdit(number, User, s) {
      this.shenheneirong = User.shenheliyou
      this.seaid = User.facilityid
      this.nub = s
      this.fnb=User.facilitynumber
      //  this.imageUrl = `/api/common/download?name=${User.img}`
    },
    // 删除方法
    deleteat() {
      const id = this.deleteId
      axios.delete(`/api/facility/defacility?id=${id}`).then((rel) => {
        console.log(rel);
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        this.info()
      })
    },
    add(){
       axios.post("/api/facility/addfacility",this.form).then((rel)=>{
        console.log(rel)
        if(rel.data.code==200){
          this.info()
        }else{
          ElMessage({
          message: '失败',
          type: 'error',
        })
        }
       })
    },

    //删除按钮行数选定
    handleDelete(index, User) {
      console.log(index, User)
      this.deleteId = User.facilityid
    },


    //身份认证
    userpost() {
      const posts = localStorage.getItem("posts")
      console.log("身份验证", posts)
      if (posts == "后端管理员" || posts == "教练负责人") {
        console.log("身份验证成功")
        this.showEventInfo1 = true;
        this.showEventInfo2 = false
      } else {
        console.log("身份验证结束")
        this.showEventInfo1 = false;
        this.showEventInfo2 = true
      }
    },
    //筛选
    //  getImage(image) {//图片遍历
    //    return `/api/common/download?name=${image}`
    //  },
  },


  mounted() {
    this.info();
    this.userpost()
  },


}
</script>

<style>
.head {
  border: 1px solid #000;
  width: 100%;
  height: 50px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.for {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 垂直居中 */
  /* 注意：这种方式会影响所有表单项，包括它们的标签 */
}
</style>