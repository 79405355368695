<template>
 
  <router-view></router-view>


 
</template>

<script>

// const debounce = (fn, delay) => {
//   let timer
//    return (...args) => {
//      if (timer) {
//        clearTimeout(timer)
//      }
//      timer = setTimeout(() => {
//        fn(...args)
//      }, delay)
//    }
//   }

// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
//    constructor(callback) {
//      callback = debounce(callback, 200);
//      super(callback);
//    }
//   }

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
 
}

</style>
