<template>
  <!-- <h1>地方俱乐部</h1> -->
  <div style="width: 100%; height: 50px;  display: flex; ">
    <div class="club" style="margin-right: 20px;margin-left: 20px;">
      <label>俱乐部:</label>
      <el-input v-model="inputclub" style="width: 150px" placeholder="Please input" />
    </div>
    <div class="host" style="margin-right: 20px;margin-left: 20px;">
      <label>负责人:</label>
      <el-input v-model="principaltow" style="width: 120px" placeholder="Please input" />
    </div>
    <el-button type="primary" @click="condition(2)">模糊查询</el-button>
    <el-button type="primary" @click="condition(1)">精确查询</el-button>
    <el-button type="primary" @click="ToAdd">添加俱乐部</el-button>
  </div>

  <div class="main">
    <el-scrollbar height="700px">
      <el-table :data="tableData" style="width: 100%" height="700">
        <el-table-column prop="clubid" label="ID" width="250" />
        <el-table-column prop="name" label="名称" width="250" />
        <el-table-column prop="address" label="地址" width="250" />
        <el-table-column prop="principal" label="负责人" width="250" />
        <el-table-column prop="img" label="照片" width="250">
          <template #default="scope">

            <el-image :src="getImage(scope.row.img)" style="width:100px ;"
              preview-src-list="=[ `/aip/common/download?name=${scope.row.image}` ]"></el-image>
          </template>
        </el-table-column>
        <el-table-column>
          <template #header>
            <div style="display: flex;">
              <el-icon @click="Searchqinxun()" style="width: 20px; height: 30px;">
                <Search />
              </el-icon>
              <input type="text"
                style=" color: #000; width:100px ; height: 20px; border-radius: 15px; margin: 5px;  border: 1px solid #000;"
                v-model="seaid">
            </div>  
          </template>
          <template #default="scope">
            <el-button v-if="showEventInfo1" plain size="small" @click="handleEdit(scope.$index, scope.row)"
              v-on:click="dialogFormVisible = true">
              编辑
            </el-button>
            <el-button v-if="showEventInfo1" size="small" type="danger" @click="handleDelete(scope.$index, scope.row)"
              plain v-on:click="centerDialogVisible = true">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>
    <div class="tanchuang">
      <el-dialog v-model="dialogFormVisible" title="编辑" width="500">
        <el-form :model="form" class="for">
          <el-form-item>
            <div>
              <el-upload ref="uploadRef" class="avatar-uploader" action="/api/common/upload" :show-file-list="false"
                :file-list="fileList" @success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                :on-change="handleFileChange" :auto-upload="false">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <button @click="submitUpload()" type="button">上传图片</button>
            </div>
          </el-form-item>
          <el-form-item label="名称">
            <el-input v-model="name" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="地址">
            <el-input v-model="address" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <el-form-item label="负责人">
            <!-- <el-select v-model="form.region" placeholder="填写职位">
              <el-option label="Zone No.1" value="shanghai" />
              <el-option label="Zone No.2" value="beijing" />
            </el-select> -->
            <el-input v-model="principal" autocomplete="off" style="width: 200px; height: 40px;" />
          </el-form-item>
          <!-- <el-form-item label="上传照片" :label-width="formLabelWidth">
            <el-input v-model="form.name" autocomplete="off"  style="width: 200px; height: 40px;"/>
          </el-form-item> -->
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogFormVisible = false"> 取消</el-button>
            <el-button type="primary" @click="dialogFormVisible = false" v-on:click="editplay()">
              保存
            </el-button>
          </div>
        </template>
      </el-dialog>

      <div class="deleteDialog">
        <el-dialog v-model="centerDialogVisible" title="消息" width="500" center>
          <span>
            是否确定删除此条数据
          </span>
          <template #footer>
            <div class="dialog-footer">
              <el-button @click="centerDialogVisible = false">否</el-button>
              <el-button type="primary" @click="centerDialogVisible = false" v-on:click="deleteat()">
                是
              </el-button>
            </div>
          </template>
        </el-dialog>
      </div>
    </div>
    <div>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

  </div>
</template>
<script setup>
import { Search } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
const dialogFormVisible = ref(false)
const centerDialogVisible = ref(false)
</script>
<script>
import { reactive, ref } from 'vue'
import axios from 'axios'
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,  //当前页
      pageSize: 10, //页面数据条数
      total: '100',
      name: '',
      clubid: '',
      address: '',
      principal: '',
      imageUrl: '',//编辑照片显示路径
      showEventInfo1: true,
      inputclub: '',
      athletesnb: '',
      deleteId:'',
      seaid:'',
      sqlimg:'',
      principaltow:'',
    }

  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.info()
    },
    //换页数
    handleCurrentChange(val) {
      this.currentPage = val;
      this.info()
    },
    //获取球员信息
    info() {
      axios.get(`/api/club/clubinfo?pageSize=${this.pageSize}&pageNum=${this.currentPage}`).then((rel) => {
        console.log(rel)
        this.tableData = rel.data.data.records
        this.currentPage = rel.data.data.current
        this.pageSize = rel.data.data.size
        this.total = rel.data.data.total
      })
    },
    
    //查询方法
    Searchqinxun() {
      const id =this.seaid
      axios.get(`/api/club//seleclubid?id=${id}`).then((rel) => {
        console.log(rel);
        
          this.tableData = rel.data.data
      })
    },
    //编辑上传图片变换
    handleFileChange(file, fileList) {
     this.imageUrl = URL.createObjectURL(file.raw);
     if (fileList.length > 0) {
       this.fileList = [fileList[fileList.length - 1]]
     }
     

     // 当文件状态发生变化时触发，此处用于更新预览图片  
   },
   //提交图片
   submitUpload() {
      this.$refs.uploadRef.submit();
    },
    //图片回显
    handleAvatarSuccess(res, file) {
     this.sqlimg = res.data;
     console.log("图片回显",this.sqlimg)
     this.imageUrl = URL.createObjectURL(file.raw);
     console.log(this.imageUrl)
   },
   //上传图片过滤
   beforeAvatarUpload(file) {
     const isJPG = file.type === 'image/jpeg';
     const isLt2M = file.size / 1024 / 1024 < 2;

     if (!isJPG) {
       this.$message.error('上传头像图片只能是 JPG 格式!');
     }
     if (!isLt2M) {
       this.$message.error('上传头像图片大小不能超过 2MB!');
     }
     return isJPG && isLt2M;
   },
    //信息回显
    handleEdit(number, User) {
      console.log(number, User)
      this.clubid = User.clubid,
        this.name = User.name,
        this.address = User.address,
        this.principal = User.principal,
        this.athletesnb = User.athletesnb
      this.imageUrl = `/api/common/download?name=${User.img}`
    },
    //编辑俱乐部  
    editplay() {
     axios.put('/api/club/Upclub', {
       clubid:this.clubid,
       name: this.name,//姓名
       address: this.address,
       athletesnb: this.athletesnb,
       principal: this.principal,
       img:this.sqlimg,
     }).then((rel) => {
       alert("修改成功")
       this.info()
     })
   },
    //删除按钮行数选定
    handleDelete(index, User) {
      console.log(index, User)
      this.deleteId = User.clubid

    },
    // 删除方法
    deleteat() {
      const id = this.deleteId
      console.log(this.deleteId)
      axios.delete(`/api/club/declub?id=${id}`).then((rel) => {
        console.log(rel);
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        this.info()
      })
    },

    //筛选
    condition(s) {
      axios.post('/api/club/condition', {
        inputclub: this.inputclub,
        inputphone: this.principaltow,
        ctype: s,
      }).then((rel) => {
        console.log(rel)
        this.tableData = rel.data.data
      })
    },
    getImage(image) {//图片遍历
      console.log(image)
      return `/api/common/download?name=${image}`
    },

    ToAdd(){
    this.$router.push({ name: 'add' });
   }
  },

  
  mounted() {
    this.info();
  },
 

}
</script>

<style></style>