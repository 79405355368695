import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/admin/Home.vue'
import login from '../views/logo.vue'
import AHassemble from '../components/AHassemble.vue'
import employee from '../views/admin/employee.vue'
import coach from '../views/admin/coach.vue'
import EventManagement from '../views/admin/EventManagement.vue'
import inform from '../views/admin/inform.vue'
import trainingData from '../views/admin/trainingData.vue'
import YouthIevel from '../views/admin/YouthIevel.vue'
import RegionalClub from '../views/admin/RegionalClub.vue'
import athketes from '../views/admin/athketes.vue'
import user from '../views/admin/user.vue'
import analyse from '../views/admin/analyse.vue'
import add from '../views/admin/add.vue'
import logs from '../views/admin/Logs.vue'
// import tj from '../views/admin/Logs.vue'
import userinter from '../views/userinterface/userHome.vue'
import userone from '../views/userinterface/userone.vue'
import usertow from '../views/userinterface/usertow.vue'

import userCompetition from '../views/userinterface/userCompetition.vue'
import field from '../views/admin/field.vue'
import facility from '../views/admin/facility.vue'
const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  // {//组件测试
  //   path: '/tj',
  //   name: 'tj',
  //   component: tj
  // },
  {
    path: '/userHome',
    name: 'userinter',
    component: userinter,
    children:[
      {
        path:'/userHome',//首页
        name:'userone',
        component:userone,
      },
      {
        path:'/userHome/usertow',//介绍
        name:'usertow',
        component:usertow,
      },
      
      {
        path:'/userHome/userCompetition',//赛事
        name:'userCompetition',
        component:userCompetition,
      },


      
    ]
  },

  {
    path: '/Home',
    name: 'AHassemble',
    component: AHassemble,
    redirect:'/Home',
    children:[
      {
        path:'/Home',//首页
        name:'home',
        component:Home,
      },
      {
        path:'/employee',//管理员信息
        name:'employee',
        component:employee,
      },
      {
        path:'/coach',//教练信息
        name:'coach',
        component:coach,
      },
      {
        path:'/EventManagement',//赛事管理
        name:'EventManagement',
        component:EventManagement,
      },
      {
        path:'/inform',//通知信息
        name:'inform',
        component:inform,
      },
      {
        path:'/trainingData',//训练数据
        name:'trainingData',
        component:trainingData,
      },
      {
        path:'/YouthIevel',//青训人员表
        name:'YouthIevel',
        component:YouthIevel,
      },
      {
        path:'/RegionalClub',//地方俱乐部
        name:'RegionalClub',
        component:RegionalClub,
      },
      {
        path:'/athketes',//青训运动员管理
        name:'athketes',
        component:athketes,
      },
      {
        path:'/user',//用户管理
        name:'user',
        component:user,
      },
      {
        path:'/analyse',//数据分析
        name:'analyse',
        component:analyse,
      },
      {
        path:'/add',//添加用户管理
        name:'add',
        component:add,
      },
      {
        path:'/logs',//日志管理
        name:'logs',
        component:logs,
      },
      {
        path:'/field',//用户管理
        name:'field',
        component:field,
      },
      {
        path:'/facility',//设备管理
        name:'facility',
        component:facility,
      },
    ]
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const token=localStorage.getItem("tokenValue")
  const tokenTimestr=localStorage.getItem("tokenTime")//时间搓
  const tokenTime = parseInt(tokenTimestr, 10)+ Math.floor(Date.now() / 1000);//
  const now = Math.floor(Date.now() / 1000);//获取当前时间戳
    // 判断当前路由是否需要认证  
    if (!token && !'/userHome/**') {  
      // 如果没有token，则重定向到登录页面  
      if(to.path!=='/'){

        next('/')
      }else{
        next()
      }
    } else {  
      if(tokenTime<=now){
        console.log(tokenTime)
        console.log(now)
        alert("token过期")
        localStorage.clear();
        next('/login')
       
      }else{
        console.log(tokenTime)
        console.log(now)

        next();  
      }
      // 如果有token，可以进一步判断token是否过期等逻辑  
      // 然后继续执行或重定向  
    }  
  
    // 确保一定要调用 next()  
 
  // console.log(to)
  //   // console.log(from)
  //   // console.log(next)
})
export default router
