<template>
    <div style="width: 660px; height: 370px;">
        <el-table :data="tableData" style="width: 100%; height: 100%;" border>
            <el-table-column type="expand" prop="contentid" label="" width="1"/>
            <el-table-column prop="content" label="代办事项" width="400" />
           
            <el-table-column label="状态" width="109 ">
                <template v-slot="scope">
                    {{ scope.row.contentstate === 0 ? '待完成' : '完成' }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="150">
                <template #default="scope">
                    <el-button :icon="Plus" circle @click="hanadd()" />
                    <el-button type="success" :icon="Check" circle @click="handleClick(scope.$index, scope.row)" />
                    <el-button type="primary" :icon="Edit" circle @click="hanedit(scope.$index, scope.row)"  />
                </template>
            </el-table-column>
        </el-table>
        <!-- //编辑工作代办弹窗 -->
        <el-dialog v-model="dialogFormVisible" title="编辑" width="500">
       <el-form :model="form" class="for" label-width="120px">
        
         <el-form-item label="待办事项">
           <el-input  type="textarea" v-model="content" autocomplete="off" style="width: 200px; height: 40px;" />
         </el-form-item>
         <el-form-item label="状态">
           <el-input v-model="contentstate" autocomplete="off" style="width: 200px; height: 40px;" />
         </el-form-item>
         <!-- <el-form-item label="上传照片" :label-width="formLabelWidth">
           <el-input v-model="form.name" autocomplete="off"  style="width: 200px; height: 40px;"/>
         </el-form-item> -->
       </el-form>
       <template #footer>
         <div class="dialog-footer">
           <el-button @click="dialogFormVisible = false"> 取消</el-button>
           <el-button type="primary" @click="dialogFormVisible = false" v-on:click="edit()">
             保存
           </el-button>
         </div>
       </template>
     </el-dialog>
        <!-- //添加工作代办 -->
     <el-dialog v-model="adDialog" title="添加" width="500">
       <el-form :model="form" class="for" label-width="120px">
        
         <el-form-item label="待办事项">
           <el-input  type="textarea" v-model="content" autocomplete="off" style="width: 200px; height: 40px;" />
         </el-form-item>
         <el-form-item label="状态">
           <el-input v-model="contentstate" autocomplete="off" style="width: 200px; height: 40px;" />
         </el-form-item>
         <!-- <el-form-item label="上传照片" :label-width="formLabelWidth">
           <el-input v-model="form.name" autocomplete="off"  style="width: 200px; height: 40px;"/>
         </el-form-item> -->
       </el-form>
       <template #footer>
         <div class="dialog-footer">
           <el-button @click="adDialog = false"> 取消</el-button>
           <el-button type="primary" @click="adDialog = false" v-on:click="add()">
             保存
           </el-button>
         </div>
       </template>
     </el-dialog>
    </div>

</template>

<script setup>
import {
    Plus,
    Check,
    Edit,
} from '@element-plus/icons-vue'
import axios from 'axios';
</script>
<script>
export default {
    data() {
        return {
            tableData: [],
            content: '',
            contentstate:'',
            dialogFormVisible:false,
            contentid:'',
            userid:'',
            username:'',
            adDialog:false
        }
    },

    methods: {
        contentdata() {
            axios.get('/api/Home/work').then((rel) => {
                console.log("人才俱乐部数量", rel)
                this.tableData = rel.data.data.map(item => ({
                    content: item.content,
                    contentstate: item.contentstate,
                    contentid:item.contentid,
                }));
                console.log(this.tableData)
            })
        },
        handleClick(index, row) {
            this.handleEdit(index, row);
            this.remove();
        },
        hanedit(index, row){
            this.handleEdit(index, row);
            this.dialogFormVisible = true;
        },

        remove() {
            const contents = this.content
            axios.delete(`/api/Home/worksc?contents=${contents}`).then((rel) => {
                console.log(rel);
                alert("完成任务")
                this.contentdata()
            })
        },
        handleEdit(number, User) {
            console.log(number, User)
            this.content = User.content
            this.contentstate=User.contentstate
            this.contentid=User.contentid
        },

        edit(){
            axios.put(`/api/Home/upworksc`,{
                content:this.content,
                contentstate:this.contentstate,
                contentid:this.contentid,
            }).then((rel)=>{
                alert("修改成功")
                this.contentdata()
            })
        },

        hanadd(){
          this.content=null,
          this.contentstate=null,
            this.adDialog = true;
        },

        add(){
          
            axios.post(`/api/Home/addworksc`,{
                content:this.content,
                contentstate:this.contentstate,
            }).then((rel)=>{
                console.log(rel)
                alert("添加成功")
                this.contentdata()
            })
        }
    },

    mounted() {
        this.contentdata();
    },
}
</script>

<style>

</style>